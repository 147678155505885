import {
  animate,
  assignAttributes,
  closest,
  createElement,
  lockScroll,
  replaceChildren,
  unlockScroll,
} from "../utils";

/**
 * A drawer component.
 *
 * @example
 * ```html
 * <button aria-controls="my-drawer">
 *   Open drawer
 * </button>
 *
 * <ifrs-drawer id="my-drawer">
 *   <ul>
 *     <!-- //... -->
 *   </ul>
 * </ifrs-drawer>
 * ```
 */
export default class Drawer extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "ifrs-drawer") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * A collection for cleanup functions, e.g. removing event listeners.
   * @type {Set}
   */
  cleanup = new Set();

  get open() {
    return Boolean(this.getAttribute("open"));
  }

  set open(value) {
    /**
     * @param {boolean} isOpen
     */
    const setState = async (isOpen) => {
      if (isOpen) {
        this.setAttribute("open", "true");
        lockScroll(this);
        this.hidden = false;
      }

      await Promise.all([
        animate(this, [{ opacity: isOpen ? 1 : 0 }]),
        animate(this.firstElementChild, [
          { transform: `translateX(${isOpen ? "0px" : "-100%"})` },
        ]),
      ]);

      if (!isOpen) {
        this.hidden = true;
        this.removeAttribute("open");
        unlockScroll();
      }
    };

    const next = Boolean(value);

    if (next !== this.open) {
      setState(next);
    }
  }

  /**
   * Set up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Set up the component markup
    if (!this.dataset.initialized) {
      // Hide the component on initialization
      assignAttributes(this, { style: { opacity: 0 }, hidden: true });

      // Create an inner wrapper component.
      const drawer = createElement("div", {
        "aria-modal": "true",
        classList: "drawer-modal",
        role: "dialog",
        style: { transform: "translateX(-100%)" },
      });

      let children = Array.from(this.children);

      // If the component's only child is a <details> element, then we replace
      // the element with its children (excluding any summary elements among
      // them). This allows <details> to be used as a fallback for no-js and
      // legacy browsers.
      if (children.length === 1 && children[0].matches("details")) {
        children = Array.from(children[0].children).filter(
          (el) => !el.matches("summary")
        );
      }

      replaceChildren(drawer, ...children);
      replaceChildren(this, drawer);

      this.dataset.initialized = true;
      document.body.append(this);
      return;
    }

    // Close the drawer when the "overlay" is clicked
    this.addEventListener("click", (event) => {
      if (!this.firstElementChild.contains(event.target)) {
        this.open = false;
      }
    });

    // Toggle state on button clicks that reference this element
    const handleClick = (event) => {
      if (closest(event.target, `[aria-controls='${this.id}']`)) {
        this.open = !this.open;
      }
    };
    document.addEventListener("click", handleClick);
    this.cleanup.add(() => {
      document.removeEventListener("click", handleClick);
    });

    // Close the drawer with the "Escape" key
    const handleKeydown = (event) => {
      if (event.key === "Escape" && this.open) {
        this.open = false;
      }
    };
    document.addEventListener("keydown", handleKeydown);
    this.cleanup.add(() => {
      document.removeEventListener("keydown", handleKeydown);
    });

    // Ensure the drawer's state is updated when the element is hidden by,
    // for example, CSS with a max-width media query. Using
    // IntersectionObserver rather than something like matchMedia allows us
    // to use react declaratively to CSS defined with a utility class
    // like `md:hidden`.
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (this.open && !entry.isIntersecting) {
            this.open = false;
          }
        });
      });
      observer.observe(this);
      this.cleanup.add(() => observer.disconnect());
    }
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
    }
  }
}
