// @ts-check

import { isPlainObject, prefersReducedMotion } from "./helpers";

/**
 * A wrapper around Element.animate(). The function waits for animations to
 * finish and includes a basic polyfill for legacy browsers.
 * @param {HTMLElement} el The element to animate.
 * @param {Keyframe[]} keyframes The animation keyframes.
 * @param {KeyframeAnimationOptions} [options] The animation options.
 * @returns {Promise<void>}
 */
export async function animate(el, keyframes, options = {}) {
  if (!(el instanceof HTMLElement)) {
    return;
  }

  if (typeof el.animate !== "function") {
    keyframes.forEach((obj) => {
      Object.assign(el.style, obj);
    });
    return;
  }

  try {
    // Get all animating properties and set 'will-change'
    const properties = [...new Set(keyframes.flatMap(Object.keys))];
    el.style.willChange = properties.join(", ");

    // Create the animation
    const animation = el.animate(keyframes, {
      duration: prefersReducedMotion() ? 10 : 150,
      easing: "cubic-bezier(0.5, 0, 0.5, 1)",
      fill: "both",
      ...options,
    });

    // Wait for the animation to finish
    await animation.finished;

    // Commit styles
    animation.commitStyles();

    // Remove 'will-change' declarations
    el.style.removeProperty("will-change");
  } catch (error) {
    // Ignore errors
  }
}

/**
 * Assigns attributes and properties to the provided target element.
 *
 * Attributes/properties may be specified as plain objects or as reference
 * Elements, in which case the reference element's attributes will be copied to
 * the target. The function also accepts an array of either.
 * @param {Element} target The target element.
 * @param {*} source Attributes to be assigned.
 * @returns {Element} The target element.
 */
export function assignAttributes(target, source) {
  if (!(target instanceof Element)) {
    return target;
  }
  if (Array.isArray(source)) {
    source.forEach((o) => assignAttributes(target, o));
  }
  if (source instanceof Element && source.hasAttributes()) {
    for (const attribute of source.attributes) {
      target.setAttribute(attribute.name, attribute.value);
    }
  }
  if (isPlainObject(source)) {
    Object.entries(source).forEach(([name, value]) => {
      if (value === null || typeof value === "undefined") {
        if (target.hasAttribute(name)) {
          target.removeAttribute(name);
        }
        if (name in target) {
          target[name] = null;
        }
      } else if (!(name in target)) {
        target.setAttribute(name, value);
      } else if (target[name] instanceof DOMTokenList) {
        // DOMTokenLists such as Element.classList get special treatment.
        // This allows adding tokens without overwriting existing ones.
        const values = Array.isArray(value) ? value : value.split(/\s+/);
        values.forEach((str) => {
          target[name].add(str.toString());
        });
      } else if (isPlainObject(value)) {
        Object.assign(target[name], value);
      } else {
        target[name] = value;
      }
    });
  }
  return target;
}

/**
 *
 * @param {Element|string} el
 * @param {*} [properties] Attributes/properties to assign the new element.
 * @param {Node|Node[]|HTMLCollection|NodeList} [children] Child nodes to append to the new element.
 * @returns {Element|null}
 */
export function cloneElement(el, properties, children) {
  let clone = el;
  if (typeof clone === "string") {
    clone = createElement("div", { innerHTML: clone }).firstElementChild;
  } else if (clone instanceof Element) {
    clone = clone.cloneNode();
  }
  if (!(clone instanceof Element)) {
    return null;
  }
  if (properties) {
    assignAttributes(clone, properties);
  }
  if (children) {
    replaceChildren(clone, children);
  }
  return clone;
}

/**
 * A type-safe wrapper around Element.closest().
 * @param {*} el The descendant element to check
 * @param {string} selector The query selector to match
 * @returns {Element|null} The matching ancestor or null if none is found
 */
export function closest(el, selector) {
  if (el instanceof Element) {
    return el.closest(selector);
  }
  if (el instanceof Node && el.parentElement) {
    return el.parentElement.closest(selector);
  }
  return null;
}

/**
 * Creates and returns the specified HTML element, optionally assigning the
 * provided properties/attributes and appending the provided child nodes.
 *
 * The function accepts a variety of formats for both attributes and children.
 * See {@link assignAttributes} and {@link replaceChildren}.
 * @param {string} tagName The name of the element to create.
 * @param {*} [properties] Attributes/properties to assign the new element.
 * @param {Node|Node[]|HTMLCollection|NodeList} [children] Child nodes to append to the new element.
 * @returns {HTMLElement} The newly created element.
 */
export function createElement(tagName, properties, children) {
  const el = document.createElement(tagName);
  if (properties) {
    assignAttributes(el, properties);
  }
  if (children) {
    replaceChildren(el, children);
  }
  return el;
}

/**
 * Attempts to focus the provided element.
 *
 * Adapted from example code provided by the WAI ARIA APG.
 * @link https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/js/dialog.js
 * @param {*} el The element to focus.
 * @returns {boolean} True if the element could be focused; false if not.
 */
export function focus(el) {
  if (!isFocusableElement(el)) {
    return false;
  }
  try {
    el.focus();
  } catch (error) {
    return false;
  }
  return document.hasFocus() && document.activeElement === el;
}

/**
 * Attempts to focus the first focusable descendant of the provided element.
 *
 * Adapted from example code provided by the WAI ARIA APG.
 * @link https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/js/dialog.js
 * @param {Node} el The parent element to search.
 * @returns {boolean} True if a focusable element could be found; false if not.
 */
export function focusFirstElement(el) {
  if (el instanceof Node) {
    for (let i = 0; i < el.childNodes.length; i++) {
      let child = el.childNodes[i];
      if (focus(child) || focusFirstElement(child)) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Checks if the provided element can be focused.
 *
 * Adapted from example code provided by the WAI ARIA APG.
 * @link https://www.w3.org/WAI/ARIA/apg/example-index/js/utils.js
 * @param {*} el The element to check.
 * @returns {boolean}
 */
export function isFocusableElement(el) {
  if (!(el instanceof HTMLElement) || ("disabled" in el && el.disabled)) {
    return false;
  }
  if (el.matches("a")) {
    return Boolean(el.href) && el.rel !== "ignore";
  }
  if (el.matches("input")) {
    return el.type !== "hidden";
  }
  if (el.matches("button, select, textarea")) {
    return true;
  }
  return false;
}

/**
 * Replaces an element's children.
 *
 * The function is meant as a stand-in for Element.replaceChildren(), which
 * is still not yet fully supported. The function also accepts children in a
 * variety of formats that Element.replaceChildren() does not, including
 * arrays, HTMLCollections and NodeLists.
 * @link https://caniuse.com/mdn-api_element_replacechildren
 * @param {Element|Node}    el       The parent element.
 * @param {...Node|Node[]|HTMLCollection|NodeList|string} children The children to append.
 */
export function replaceChildren(el, ...children) {
  const items = children.flatMap((item) =>
    item instanceof HTMLCollection || item instanceof NodeList
      ? Array.from(item)
      : item
  );
  if (el instanceof Element && typeof el.replaceChildren === "function") {
    el.replaceChildren(...items);
  } else if (el instanceof Node) {
    while (el.firstChild) {
      el.removeChild(el.firstChild);
    }
    for (let child of children) {
      const node =
        typeof child === "string" ? document.createTextNode(child) : child;
      if (node instanceof Node) {
        el.appendChild(node);
      }
    }
  }
}

/**
 * Submits the provided form.
 *
 * This is meant to be a replacement for HTMLFormElement.requestSubmit()
 * that's safe for legacy browsers.
 * @link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit
 * @param {HTMLFormElement} form        The form to submit.
 * @param {HTMLElement}     [submitter] A submit button to use.
 */
export function requestSubmit(form, submitter) {
  if (typeof form.requestSubmit === "function") {
    form.requestSubmit(submitter);
  } else if (
    submitter instanceof Element &&
    submitter.matches("button, [type='submit']")
  ) {
    submitter.click();
  } else {
    form.dispatchEvent(new CustomEvent("submit", { cancelable: true }));
  }
}
