// @ts-check

import { autoUpdate, computePosition, shift } from "@floating-ui/dom";

import chevronIconSVG from "../../icons/chevron-down.svg?raw";
import {
  animate,
  assignAttributes,
  cloneElement,
  createElement,
  replaceChildren,
  uniqueId,
} from "../utils";

export default class Disclosure extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "ifrs-disclosure") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Defines observed attributes
   */
  static observedAttributes = ["open"];

  /**
   * A collection for cleanup functions, e.g. removing event listeners.
   * @type {Set}
   */
  cleanup = new Set();

  /**
   * @type {boolean} The current state of the disclosure
   */
  get open() {
    return this.hasAttribute("open");
  }

  /**
   * @param {boolean} value The new state of the disclosure
   */
  set open(value) {
    this.toggleAttribute("open", !!value);
  }

  /**
   * Reacts to changes of observed attributes. The function makes DOM updates
   * to reflect the current state of the component.
   * @param {string} name The changed attribute name
   * @param {*} prev The previous value of the attribute
   * @param {*} next The new value of the attribute
   */
  async attributeChangedCallback(name, prev, next) {
    if (name === "open") {
      const button = this.querySelector(".disclosure-item-button");
      const content = this.querySelector(".disclosure-item-content");
      const container = this.querySelector(".disclosure-item-container");

      if (!(button && container && content)) {
        return;
      }

      const isExpanded = next !== null;

      button.setAttribute("aria-expanded", isExpanded.toString());

      const duration = this.dataset.duration
        ? parseInt(this.dataset.duration)
        : 150;

      if (this.hasAttribute("haspopup")) {
        const setPosition = async () => {
          // Compute position
          const position = await computePosition(this, container, {
            strategy: "fixed",
            placement: "bottom",
            middleware: [shift()],
          });
          // Assign styles
          Object.assign(container.style, {
            left: `${position.x}px`,
            top: `${position.y}px`,
            position: position.strategy,
          });
        };

        if (isExpanded) {
          // Un-hide the container element
          container.hidden = false;
          // Save Floating UI clean up functions
          const cleanup = autoUpdate(button, container, setPosition);
          this.cleanupFloatingUI = () => {
            cleanup();
            delete this.cleanupFloatingUI;
          };

          this.cleanup.add(() => {
            if (this.cleanupFloatingUI) {
              this.cleanupFloatingUI();
            }
          });
        }

        // Animate the content in/out
        await animate(
          container,
          [
            {
              opacity: isExpanded ? 1 : 0,
              transform: `translateY(${isExpanded ? "0" : "1rem"})`,
            },
          ],
          { duration }
        );

        // On collapse, hide the content once the animation has completed.
        if (button.getAttribute("aria-expanded") !== "true") {
          container.hidden = true;
          if (this.cleanupFloatingUI) {
            this.cleanupFloatingUI();
          }
        }
      }

      if (!this.hasAttribute("haspopup")) {
        container.style.overflow = "hidden";
        content.style.visibility = null;
        await animate(
          container,
          [{ gridTemplateRows: isExpanded ? "1fr" : "0fr" }],
          { duration }
        );
        container.style.overflow = isExpanded ? null : "hidden";
        content.style.visibility = isExpanded ? null : "hidden";
      }

      this.dispatchEvent(
        new Event("toggle", { bubbles: true, cancelable: true })
      );
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected.
    if (!this.isConnected) {
      return;
    }

    // Set up the component if not done yet.
    if (!this.dataset.setup) {
      const details = this.querySelector("details");
      const summary = details && details.querySelector("summary");

      if (!(details && summary)) {
        return;
      }

      assignAttributes(this, details);

      summary.remove();

      const link = summary.querySelector("a");

      if (link) {
        link.remove();
      }

      const id = uniqueId(this.tagName);

      const isExpanded = this.hasAttribute("open");

      const chevronIcon = cloneElement(chevronIconSVG, {
        classList: "icon disclosure-item-icon",
        xmlns: null,
      });

      const button = createElement(
        "button",
        [
          summary,
          {
            "aria-controls": id,
            "aria-expanded": isExpanded ? "true" : "false",
            id: `${id}-button`,
            classList: "disclosure-item-button",
            type: "button",
          },
        ],
        [...summary.childNodes, chevronIcon]
      );

      const content = createElement(
        "div",
        { classList: "disclosure-item-content", id },
        details.childNodes
      );

      const container = createElement(
        "div",
        { classList: "disclosure-item-container" },
        content
      );

      if (this.hasAttribute("haspopup")) {
        assignAttributes(container, {
          hidden: true,
          style: { opacity: 0, transform: "translateY(1rem)" },
        });
      }

      if (!this.hasAttribute("haspopup")) {
        assignAttributes(content, {
          "aria-labelledby": `${id}-button`,
          role: "region",
          style: {
            minHeight: 0,
            visibility: isExpanded ? null : "hidden",
          },
        });
        assignAttributes(container, {
          style: {
            display: "grid",
            gridTemplateRows: isExpanded ? "1fr" : "0fr",
            overflow: isExpanded ? null : "hidden",
          },
        });
      }

      replaceChildren(this, link || "", button, container);

      this.dataset.setup = "true";
    }

    if (this.dataset.setup) {
      const handleClick = () => {
        this.open = !this.open;
      };

      this.querySelectorAll("button[aria-expanded]").forEach((el) => {
        el.addEventListener("click", handleClick);
        this.cleanup.add(() => el.removeEventListener("click", handleClick));
      });

      /**
       *
       * @param {FocusEvent} event
       */
      const handleFocusOut = (event) => {
        console.log("handleFocusOut()");
        if (
          !(event.relatedTarget instanceof Node) ||
          !this.contains(event.relatedTarget)
        ) {
          this.open = false;
        }
      };

      this.addEventListener("focusout", handleFocusOut);
      this.cleanup.add(() =>
        this.removeEventListener("focusout", handleFocusOut)
      );
    }
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }
}
