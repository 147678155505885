import {
  assignAttributes,
  createElement,
  replaceChildren,
  uniqueId,
} from "../utils";

export default class Tabs extends HTMLElement {
  /**
   * Define the element in the document's custom element registry.
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "ifrs-tabs") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * A collection for cleanup functions, e.g. removing event listeners.
   * @type {Set}
   */
  cleanup = new Set();

  /**
   * Set up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected.
    if (!this.isConnected) {
      return;
    }

    if (!this.dataset.initialized) {
      this.dataset.initialized = true;

      const children =
        this.childElementCount === 1 &&
        this.firstElementChild.matches("dl,ol,ul")
          ? this.firstElementChild.children
          : this.children;

      const tablist = createElement("div", { role: "tablist" });

      const tabpanels = Array.from(children).map((el, index) => {
        const isSelected = index === 0;
        const tabId = uniqueId("ifrs-tab-");
        const panelId = `${tabId}-panel`;

        const tab = createElement("button", {
          "aria-controls": panelId,
          "aria-selected": String(isSelected),
          id: tabId,
          role: "tab",
          tabindex: isSelected ? 0 : -1,
          type: "button",
        });

        const heading = el.querySelector("h1,h2,h3,h4,h5,h6");

        if (heading) {
          heading.remove();
        }

        tab.textContent = heading ? heading.textContent : `Tab ${index}`;

        tablist.append(tab);

        const panel = createElement("section", {
          "aria-labelledby": tabId,
          hidden: !isSelected,
          id: panelId,
          role: "tabpanel",
          tabindex: 0,
        });

        replaceChildren(panel, ...el.children);

        return panel;
      });

      replaceChildren(this, tablist, ...tabpanels);
    }

    const setTabState = (tab, state) => {
      const id =
        tab instanceof Element &&
        tab.matches("[role='tab'][aria-controls]") &&
        tab.getAttribute("aria-controls");

      const panel = id && document.getElementById(id);

      if (tab && panel) {
        const next =
          typeof state === "boolean"
            ? state
            : !(tab.getAttribute("aria-selected") === "true");

        if (next) {
          this.querySelectorAll("[role='tab'][aria-selected='true']").forEach(
            (el) => {
              if (el !== tab) {
                setTabState(el, false);
              }
            },
          );
        }

        assignAttributes(tab, {
          "aria-selected": next.toString(),
          tabindex: next ? 0 : -1,
        });
        panel.hidden = !next;
      }
    };

    this.querySelectorAll("[role='tablist']").forEach((tablist) => {
      const handleKeydown = (event) => {
        const tabs = Array.from(tablist.querySelectorAll("[role='tab']"));
        const length = tabs.length;
        const current =
          tabs.find(
            (el) =>
              document.activeElement && el.contains(document.activeElement),
          ) || tabs.find((el) => el.matches("[aria-selected='true']"));
        let index = tabs.indexOf(current);

        if (event.key === "ArrowLeft") {
          index -= 1;
        } else if (event.key === "ArrowRight") {
          index += 1;
        } else if (event.key === "Home") {
          index = 0;
        } else if (event.key === "End") {
          index = length - 1;
        } else {
          return;
        }

        index = index < 0 ? length - 1 : index >= length ? 0 : index;

        const next = tabs[index];

        if (next) {
          next.focus();
        }
      };

      tablist.addEventListener("keydown", handleKeydown);
      this.cleanup.add(() =>
        tablist.removeEventListener("keydown", handleKeydown),
      );
    });

    this.querySelectorAll("[role='tab']").forEach((tab) => {
      const handleClick = () => {
        if (!tab === document.activeElement) {
          tab.focus();
        }
        setTabState(tab, true);
      };
      tab.addEventListener("click", handleClick);
      this.cleanup.add(() => tab.removeEventListener("click", handleClick));
    });
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
    }
  }
}
