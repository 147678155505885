import Accordion from "./Accordion";
import Banner from "./Banner";
import Disclosure from "./Disclosure";
import Drawer from "./Drawer";
import NavigationMenu from "./NavigationMenu";
import Tabs from "./Tabs";
import Tooltip from "./Tooltip";

export {
  Accordion,
  Banner,
  Disclosure,
  Drawer,
  NavigationMenu,
  Tabs,
  Tooltip,
  defineElements,
};

function defineElements() {
  if ("customElements" in window) {
    Accordion.define();
    Banner.define();
    Disclosure.define();
    Drawer.define();
    NavigationMenu.define();
    Tabs.define();
    Tooltip.define();
  }
}
