import { createElement } from "../utils";
import Disclosure from "./Disclosure";

/**
 * A navigation menu component with disclosure item sub menus.
 *
 * The component follows the "Disclosure Navigation Menu with Top-Level Links"
 * example from the WAI ARIA Authoring Practices Guide. See
 * {@link https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/examples/disclosure-navigation-hybrid/}.
 *
 * Any children elements that have both `aria-expanded` and `aria-controls`
 * attributes will be treated as disclosure buttons, and the element referenced
 * by `aria-controls` disclosure or "popover" content.
 *
 * @example
 * ```html
 * <ifrs-navigation-menu>
 *   <ul>
 *     <li>
 *       <button aria-expanded="false" aria-controls="submenu-1" type="button">
 *       <ul id="submenu-1">
 *         <!-- //... -->
 *       </ul>
 *     <li>
 *   </ul>
 * <ifrs-navigation-menu>
 * ```
 */
export default class NavigationMenu extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "ifrs-navigation-menu") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * A collection for cleanup functions, e.g. removing event listeners.
   * @type {Set}
   */
  cleanup = new Set();

  /**
   * @type {number|null}
   */
  disclosureCollapseTimeout = null;

  /**
   * Set up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Set up the component if not done yet.
    if (!this.dataset.setup) {
      this.querySelectorAll("details").forEach((details) => {
        const summary = details.querySelector("summary");
        const menuItem = details.closest(".menu-item");

        if (summary) {
          summary.after(
            createElement("strong", {
              classList: "disclosure-item-heading",
              textContent: summary.textContent,
            })
          );
        }

        details.replaceWith(
          createElement("ifrs-disclosure", [
            details,
            { innerHTML: details.outerHTML, haspopup: true },
          ])
        );

        if (menuItem && this.contains(menuItem)) {
          menuItem.classList.add("menu-item-haspopup");
        }
      });

      this.dataset.setup = "true";
    }

    if (this.dataset.setup) {
      /**
       * @param {Disclosure} disclosure
       * @param {boolean} [state]
       */
      const toggleDisclosureState = (disclosure, state) => {
        if (disclosure instanceof Disclosure) {
          if (this.disclosureCollapseTimeout) {
            clearTimeout(this.disclosureCollapseTimeout);
            this.disclosureCollapseTimeout = null;
          }
          const next = typeof state === "boolean" ? state : !disclosure.open;
          const siblings = this.querySelectorAll("ifrs-disclosure[open]");
          if (next && siblings.length > 0) {
            disclosure.dataset.duration = 10;
            siblings.forEach((el) => (el.dataset.duration = 10));
          }
          disclosure.open = next;
        }
      };

      /**
       * @param {Event} event
       */
      const handleMouseEnter = (event) => {
        if (event.currentTarget instanceof HTMLElement) {
          toggleDisclosureState(
            event.currentTarget.querySelector("ifrs-disclosure"),
            true
          );
        }
      };

      /**
       * @param {Event} event
       */
      const handleMouseLeave = (event) => {
        console.log("handleMouseLeave()");
        if (event.currentTarget instanceof HTMLElement) {
          const disclosure =
            event.currentTarget.querySelector("ifrs-disclosure");
          this.disclosureCollapseTimeout = setTimeout(() => {
            toggleDisclosureState(disclosure, false);
          }, 400);
        }
      };

      this.querySelectorAll(".menu-item-haspopup").forEach((menuItem) => {
        menuItem.addEventListener("mouseenter", handleMouseEnter);
        menuItem.addEventListener("mouseleave", handleMouseLeave);
        this.cleanup.add(() => {
          menuItem.removeEventListener("mouseenter", handleMouseEnter);
          menuItem.removeEventListener("mouseleave", handleMouseLeave);
        });
      });

      /**
       * @param {Event} event
       */
      const handleToggle = (event) => {
        if (event.target instanceof Disclosure) {
          const disclosure = event.target;
          delete disclosure.dataset.duration;
          if (disclosure.open) {
            this.querySelectorAll("ifrs-disclosure").forEach((el) => {
              if (el !== disclosure) {
                el.open = false;
              }
            });
          }
        }
      };

      this.addEventListener("toggle", handleToggle);
      this.cleanup.add(() => this.removeEventListener("toggle", handleToggle));
    }
  }
}
