import { defineElements } from "./components";
import { initializeSwiperInstance } from "./modules/swiper";
import { onDocumentReady } from "./utils";

// Define custom elements
defineElements();

onDocumentReady(function main() {
  // Set aspect ratios on ".embed" iframes
  document
    .querySelectorAll(".embed iframe:not([style*='--aspect-ratio'])")
    .forEach((el) => {
      el.style.setProperty(
        "--aspect-ratio",
        (el.width || 0) / (el.height || 1)
      );
    });

  // Initialize Swiper carousels
  document.querySelectorAll(".swiper").forEach((el) => {
    initializeSwiperInstance(el);
  });

  // Add company autocomplete to Hubspot forms
  const hubspotForms = document.querySelectorAll(".hbspt-form");
  if (hubspotForms.length > 0) {
    import("./modules/hubspot").then(({ addCompanyAutocomplete }) => {
      hubspotForms.forEach(addCompanyAutocomplete);
    });
  }
});
