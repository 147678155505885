import { animate, createElement, replaceChildren, uniqueId } from "../utils";

/**
 * An accordion component.
 *
 * The component is "light DOM" only and is meant to progressively enhance
 * "accordion-like" markup. Accordion items should be provided as `<details>`
 * element children.
 *
 * @example
 * ```html
 * <ifrs-accordion>
 *   <details>
 *     <summary>Item 1</summary>
 *     <p>This is the content for item 1.</p>
 *   </details>
 *   <details>
 *     <summary>Item 2</summary>
 *     <p>This is the content for item 2.</p>
 *     <p>Accordion item content may span multiple child elements.</p>
 *   </details>
 * <ifrs-accordion>
 * ```
 */
export default class Accordion extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "ifrs-accordion") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * A collection for cleanup functions, e.g. removing event listeners.
   * @type {Set}
   */
  cleanup = new Set();

  /**
   * Set up the element once it has been added to the DOM
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Initialize the component markup if it hasn't yet been done
    if (!this.dataset.initialized) {
      // Create accordion item markup from children
      const items = Array.from(this.children).map((el) => {
        if (!el.matches("details")) {
          return;
        }

        const summary = el.querySelector("summary");
        if (summary) {
          summary.remove();
        }
        const id = uniqueId("ifrs-accordion-");
        const isExpanded = el.open;

        // the accordion item
        return createElement(
          "div",
          [el, { classList: "accordion-item" }],
          [
            // item trigger button
            createElement("button", [
              summary || {},
              {
                classList: "accordion-item-button",
                innerHTML: summary ? summary.innerHTML : "Expand",
                id: `${id}-button`,
                type: "button",
                "aria-controls": id,
                "aria-expanded": isExpanded ? "true" : "false",
              },
            ]),
            // item content outer container
            createElement(
              "div",
              {
                style: {
                  display: "grid",
                  gridTemplateRows: "0fr",
                  overflow: isExpanded ? null : "hidden",
                },
              },
              // item content inner container
              createElement(
                "div",
                {
                  "aria-labelledby": `${id}-button`,
                  className: "accordion-item-content",
                  id,
                  role: "region",
                  style: {
                    minHeight: 0,
                    visibility: isExpanded ? null : "hidden",
                  },
                },
                el.children,
              ),
            ),
          ],
        );
      });

      // Replace inner HTML
      replaceChildren(this, ...items.filter(Boolean));

      // Mark the component as initialized
      this.dataset.initialized = "true";
    }

    /**
     * A click handler that toggles the "expanded" state of an accordion item.
     * @param {PointerEvent} event
     */
    const handleClick = async (event) => {
      const button =
        event.currentTarget instanceof Element &&
        event.currentTarget.matches("button.accordion-item-button") &&
        event.currentTarget;
      const container = button && button.nextElementSibling;
      const content = container && container.firstElementChild;

      if (container && content) {
        const isExpanded = !(button.getAttribute("aria-expanded") === "true");
        button.setAttribute("aria-expanded", isExpanded.toString());
        container.style.overflow = "hidden";
        content.style.visibility = null;
        await animate(container, [
          { gridTemplateRows: isExpanded ? "1fr" : "0fr" },
        ]);
        container.style.overflow = isExpanded ? null : "hidden";
        content.style.visibility = isExpanded ? null : "hidden";
      }
    };

    this.querySelectorAll("button.accordion-item-button").forEach((el) => {
      el.addEventListener("click", handleClick);
      this.cleanup.add(() => el.removeEventListener("click", handleClick));
    });
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }
}
