// @ts-check

import Swiper from "swiper";
import { A11y, Autoplay, Navigation } from "swiper/modules";

import { createElement } from "../utils/dom";

import pauseIcon from "../../icons/pause.svg?raw";
import playIcon from "../../icons/play.svg?raw";

/**
 * Initializes a Swiper instance.
 *
 * This function and the Swiper imports are separated from other business
 * logic to enable conditional inclusion and better tree-shaking.
 */
export function initializeSwiperInstance(el) {
  // Get play/pause button
  let button = el.querySelector(".swiper-button-play");

  // Create play/pause button if none exists
  if (!button) {
    button = createElement(
      "button",
      {
        "aria-label": "Stop automatic slide show",
        className: "swiper-button-play",
        type: "button",
      },
      [
        createElement("span", {
          "aria-hidden": "true",
          classList: "icon play-icon",
          hidden: true,
          innerHTML: playIcon,
        }),
        createElement("span", {
          "aria-hidden": "true",
          classList: "icon pause-icon",
          hidden: true,
          innerHTML: pauseIcon,
        }),
      ],
    );
    el.prepend(button);
  }

  // Add autoplay-related event handlers
  button.addEventListener("click", () => {
    if (el.swiper) {
      if (el.swiper.autoplay.running && !el.swiper.autoplay.paused) {
        el.swiper.autoplay.stop();
      } else {
        el.swiper.autoplay.start();
      }
    }
  });

  /**
   * React to autoplay state changes
   */
  const handleAutoplay = (swiper) => {
    console.log("handleAutoplay()", swiper);
    const isPlaying = swiper.autoplay.running;
    // Update button text
    const control = isPlaying ? "Stop" : "Start";
    button.setAttribute("aria-label", `${control} automatic slide show`);
    // Toggle icon visibility
    button.querySelectorAll(".icon").forEach((icon) => {
      if (icon.matches(".play-icon")) {
        icon.hidden = isPlaying;
      }
      if (icon.matches(".pause-icon")) {
        icon.hidden = !isPlaying;
      }
    });
  };

  /**
   * @type {import('swiper').Swiper['originalParams']}
   */
  let options = {
    autoplay: false,
    breakpoints: { 448: { autoplay: { delay: 5000 } } },
    loop: true,
    modules: [A11y, Autoplay],
    on: {
      autoplayStart: handleAutoplay,
      autoplayStop: handleAutoplay,
      init: handleAutoplay,
      breakpoint: (swiper, params) => {
        button.hidden = Boolean(!params.autoplay);
        if (params.autoplay) {
          if (swiper.autoplay.running) {
            swiper.autoplay.stop();
          } else {
            swiper.autoplay.start();
          }
        }
      },
    },
  };

  if (el.querySelectorAll(".swiper-button-prev, .swiper-button-next").length) {
    options = {
      ...options,
      navigation: {
        nextEl: el.querySelector(".swiper-button-next"),
        prevEl: el.querySelector(".swiper-button-prev"),
      },
      modules: [...(options.modules || []), Navigation],
    };
  }

  new Swiper(el, options);
}
