import {
  arrow as arrowMiddleware,
  autoPlacement,
  autoUpdate,
  computePosition,
  inline,
  size,
} from "@floating-ui/dom";

import {
  assignAttributes,
  createElement,
  prefersReducedMotion,
  replaceChildren,
  uniqueId,
} from "../utils";

const ENTER_DELAY = 300;
const EXIT_DELAY = 500;

export default class Tooltip extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry.
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "ifrs-tooltip") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Set up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected or if it has already been
    // initialized.
    if (!this.isConnected) {
      return;
    }

    if (!this.dataset.initialized) {
      if (
        this.childElementCount !== 1 ||
        !this.firstElementChild.matches("details") ||
        !this.firstElementChild.querySelector("summary")
      ) {
        this.dataset.initialized = false;
        return;
      }

      const id = uniqueId("ifrs-tooltip-");
      const details = this.querySelector("details");
      const summary = this.querySelector("summary");
      summary.remove();

      // Create label element
      const label = createElement("span", summary);
      assignAttributes(label, {
        innerHTML: summary.innerHTML,
        "aria-describedby": id,
        tabIndex: 0,
      });
      label.classList.add("tooltip-label");

      // Create tooltip content element
      const content = createElement("div", {
        id,
        innerHTML: details.innerHTML,
        role: "tooltip",
        classList: "tooltip-content",
      });

      // Create tooltip arrow element
      const arrow = createElement("span", {
        classList: "tooltip-arrow",
        style: { position: "absolute" },
      });

      // Create tooltip element
      const tooltip = createElement("div", {
        hidden: true,
        classList: "tooltip",
        style: { position: "fixed", left: 0, top: 0, right: "auto" },
      });
      replaceChildren(tooltip, arrow, content);

      // Save element references
      this.elements = { arrow, content, label, tooltip };

      // Replace component content with new markup
      replaceChildren(this, label, tooltip);

      // Initialize state
      this.transition = {
        in: false,
        timeout: null,
        animation: undefined,
      };

      // Intialize visibility animation
      if (typeof Animation !== "undefined") {
        this.transition.animation = new Animation(
          new KeyframeEffect(
            tooltip,
            [
              { opacity: 0, transform: "translateY(1rem)" },
              { opacity: 1, transform: "translateY(0)" },
            ],
            {
              duration: 200,
              easing: "cubic-bezier(0, 0.5, 0.5, 1)",
              fill: "both",
            },
          ),
        );
      }

      this.dataset.initialized = true;
    }

    const setState = async (state) => {
      const isEntering = state;

      if (this.transition.timeout) {
        clearTimeout(this.transition.timeout);
      }

      if (this.transition.animation) {
        this.transition.animation.cancel();
      }

      if (this.transition.in === isEntering) {
        return;
      }

      const { arrow, label, tooltip } = this.elements;

      // Compute and set tooltip position with Floating UI
      const setPosition = async () => {
        // Compute position
        const position = await computePosition(label, tooltip, {
          strategy: "fixed",
          middleware: [
            arrowMiddleware({ element: arrow }),
            autoPlacement({
              allowedPlacements: [
                "bottom",
                "bottom-end",
                "bottom-start",
                "top",
                "top-end",
                "top-start",
              ],
              boundary: document.body,
            }),
            inline(),
            size({
              apply({ availableHeight, elements }) {
                Object.assign(elements.floating.style, {
                  maxHeight: `${availableHeight}px`,
                });
              },
            }),
          ],
        });

        // Assign styles
        Object.assign(tooltip.style, {
          left: `${position.x}px`,
          top: `${position.y}px`,
        });

        // Handle arrow placement
        if (position.middlewareData.arrow) {
          const { x } = position.middlewareData.arrow;
          Object.assign(arrow.style, {
            left: x !== null ? `${x}px` : "",
            top: position.placement.includes("top") ? "" : 0,
            bottom: position.placement.includes("bottom") ? "" : 0,
          });
        }
      };

      this.transition.timeout = setTimeout(
        async () => {
          try {
            if (isEntering) {
              // Show tooltip
              tooltip.hidden = false;
              // Set Floating UI positioning
              this.transition.cleanup = autoUpdate(label, tooltip, setPosition);
              // Animate in
              if (this.transition.animation && !prefersReducedMotion()) {
                this.transition.animation.updatePlaybackRate(1);
                this.transition.animation.play();
                await this.transition.animation.finished;
              }
              // Set state
              this.transition.in = true;
            } else {
              // Clean up Floating UI event listeners
              if (this.transition.cleanup) {
                this.transition.cleanup();
                this.transition.cleanup = undefined;
              }
              // Animate out
              if (this.transition.animation && !prefersReducedMotion()) {
                this.transition.animation.updatePlaybackRate(-1);
                this.transition.animation.play();
                await this.transition.animation.finished;
              }
              // Hide tooltip
              tooltip.hidden = true;
              // Set state
              this.transition.in = false;
            }
          } catch (error) {
            // Animation.cancel() may throw a DOMException named "AbortError".
            // In such cases, we'll revert to the previous state.
            if (error.name === "AbortError") {
              this.transition.animation.reverse();
              tooltip.hidden = isEntering;
              this.transition.in = !isEntering;
            } else {
              // TODO Handle error reporting
            }
          }
        },
        isEntering ? ENTER_DELAY : EXIT_DELAY,
      );
    };

    // Bind event handlers
    this.addEventListener("mouseenter", () => setState(true));
    this.addEventListener("mouseleave", () => setState(false));
    this.addEventListener("focusin", () => setState(true));
    this.addEventListener("focusout", () => setState(false));
    this.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        setState(false);
      }
    });
  }
}
