import { createElement } from "../utils";

/**
 * A banner component.
 *
 * The component manages classes and CSS variables that can be used to style
 * a "sticky" header element.
 *
 * @example
 * ```html
 * <ifrs-banner>
 *   <div class="banner-sticky">
 *     <ul>
 *       <!-- //... -->
 *     </ul>
 *   </div>
 * </ifrs-banner>
 * ```
 */
export default class Banner extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry.
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "ifrs-banner") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Set up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected or if it has already been
    // initialized.
    if (!this.isConnected || this.dataset.initialized) {
      return;
    }

    // Maintain references to the element's dimensions as CSS variables.
    if ("ResizeObserver" in window) {
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          let entryHeight = 0;
          if (entry.borderBoxSize && entry.borderBoxSize.length > 0) {
            entryHeight = entry.borderBoxSize[0].blockSize;
          } else if (entry.contentRect) {
            entryHeight = entry.contentRect.height;
          }

          const inset = Array.from(entry.target.children)
            .filter((el) => el.matches(".banner-sticky"))
            .reduce((acc, el) => {
              el.style.setProperty("--banner-offset-top", `${acc}px`);
              return acc + el.getBoundingClientRect().height;
            }, 0);

          document.body.style.setProperty(
            "--banner-offset-top",
            `${inset - entryHeight}px`,
          );

          document.body.style.setProperty("--banner-inset-top", `${inset}px`);
        });
      });
      observer.observe(this);
    }

    // Toggle the "banner-stuck" class as the document is scrolled to indicate
    // banner element's relative position.
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.classList.remove("banner-stuck");
          } else {
            this.classList.add("banner-stuck");
          }
        });
      });

      const placeholder = createElement("div", {
        "aria-hidden": "true",
        style: { position: "relative" },
      });

      const sham = createElement("div", {
        style: {
          height:
            "calc(0px - var(--banner-offset-top, 0px) - var(--safe-area-inset-top))",
          position: "absolute",
        },
      });

      placeholder.append(sham);
      this.before(placeholder);
      observer.observe(sham);
    }

    this.dataset.initialized = true;
  }
}
